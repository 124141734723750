<template>
  <div class="flex-1 overflow-hidden flex items-center">
    <media avatar-size="34" :src="avatar" class="mr-2"></media>
    <div class="flex-1 overflow-hidden">
      <div class="text-sm text-gray-700 font-medium">{{userName}}</div>
      <div class="text-xs text-gray-light mt-1-1/2 truncate">{{info}}</div>
    </div>
  </div>
</template>

<script>
import { Media } from "./";
export default {
  name: 'UserBlock',
  components: {
    Media
  },
  props: {
    userName: String,
    info: String,
    avatar: String
  }
}
</script>

<style>

</style>