<template>
  <div  class="pl-4 bg-white relative" @click="$emit('click')">
    <div class="pr-3 py-3 flex items-center relative" :class="{'hairline-bottom': !noHairline, 'h-11': !subtitle}">
      <i class="iconfont text-2xl mr-3" :class="[icon,iconColor]" v-if="icon"></i>
      <div class="flex-1 overflow-hidden text-base">
        <slot></slot>
        <div class="text-xs mt-2 text-gray-light" v-if="subtitle">{{subtitle}}</div>
      </div>
      <div class=" mx-2" :class="noteClass" v-if="note">{{note}}</div>
      <slot name="right"></slot>
      <i class="iconfont icon-arrow-right text-gray-400 text-xl" v-if="showArrow"></i>
    </div>
    <router-link :to="url" class=" absolute inset-0" v-if="url"></router-link>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    iconColor: String,
    note: String,
    subtitle: String,
    noArrow: {
      type: Boolean,
      default: false,
    },
    noteClass: {
      type: String,
      default: 'text-xs text-gray-light',
    },
    noHairline: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: ''
    },
  },
  data(){
    return{
      
    }
  },
  computed: {
    showArrow() {
      if (this.url || this._events.click) {
        return true
      }else{
        return false
      }
    }
  },
  methods:{
    
  },
  created(){
  }
}
</script>

<style lang='stylus'>

</style>