<template>
  <vue-d-player ref="player" :options="options" @play="play" />
</template>

<script>
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
export default {
  props: {
    src: {
      type: String,
      default: ""
    },
    poster: {
      type: String,
      default: ""
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    live: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: "zh-cn" // 'en', 'zh-tw'
    },
    logo: {
      type: String,
      default: ""
    },
    videoType: {
      type: String,
      default: "auto" //'auto', 'hls', 'flv', 'dash'
    }
  },
  components: {
    VueDPlayer
  },
  data() {
    return {
      options: {
        video: {
          url: this.src,
          pic: this.poster,
          type: this.videoType
        },
        live: this.live,
        loop: this.loop,
        autoplay: this.autoplay,
        lang: this.lang,
        logo: this.logo,
        contextmenu: [
          {
            text: "GitHub",
            link: "https://github.com/MoePlayer/vue-dplayer"
          }
        ]
      },
      player: null
    };
  },
  methods: {
    play() {
      console.log("play callback");
    },
    switchHandle() {
      this.player.switchVideo({
        url: "http://static.smartisanos.cn/common/video/video-jgpro.mp4"
      });
    }
  },
  mounted() {
    this.player = this.$refs.player.dp;
  }
};
</script>

<style>
video{
  object-fit: fill;
}
</style>