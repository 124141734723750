<template>
  <div class="wrap">
    <div class="divider transform scale-y-33 h-px" :class="`bg-gray-${lightness}`" v-if="!vertical"></div>
    <div class="divider transform scale-x-33 w-px h-full" :class="`bg-gray-${lightness}`" v-else></div>
  </div>
</template>

<script>
export default {
  props: {
    lightness: {
      type: String,
      default: '300'
    },
    vertical: {
      type: Boolean,
      default: false
    },

  }
}
</script>

<style>

</style>