<template>
  <span 
    class="inline-block rounded text-xs leading-none bg-opacity-10" 
    :class="[`text-${color}-500`,`bg-${color}-500`, padding]"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'green'
    },
    padding: {
      type: String,
      default: 'p-1-1/2'
    },
  }
}
</script>

<style>

</style>