<template>
  <div class="bg-white pl-4 pr-3 py-4 flex items-center relative" @click="$emit('click')">
    <div class="flex-1 overflow-hidden">
      <slot></slot>
    </div>
    <div class="iconfont icon-arrow-right text-xl text-gray-500 ml-1"></div>
    <router-link :to="url" class="absolute inset-0" v-if="url"></router-link>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>

</style>