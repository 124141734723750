import Vue from 'vue'
import Btn from "./btn";
import HeaderBar from "./header-bar";
import Page from "./page";
import TitleBar from "./title-bar";
import List from "./list";
import MediaItem from "./media-item";
import Media from "./media";
import Tag from "./tag";
import IconText from "./icon-text";
import Card from "./card";
import UserBlock from "./user-block";
import Divider from "./divider";
import Comment from "./comment";
import GridCard from "./grid-card";
import Cell from "./cell";
import Badge from "./badge";
import ArrowWrap from "./arrow-wrap";
import Icon from "./icon";

Vue.component('btn', Btn)
Vue.component('header-bar', HeaderBar)
Vue.component('page', Page)
Vue.component('title-bar', TitleBar)
Vue.component('list', List)
Vue.component('media-item', MediaItem)
Vue.component('media', Media)
Vue.component('tag', Tag)
Vue.component('icon-text', IconText)
Vue.component('card', Card)
Vue.component('user-block', UserBlock)
Vue.component('divider', Divider)
Vue.component('comment', Comment)
Vue.component('grid-card', GridCard)
Vue.component('cell', Cell)
Vue.component('badge', Badge)
Vue.component('arrow-wrap', ArrowWrap)
Vue.component('icon', Icon)