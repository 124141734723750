export default {
  picture: "https://images.unsplash.com/photo-1579452458333-4787226ba9ca?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60",
  avatar: "https://ae01.alicdn.com/kf/U7cb6979be3aa4251b0ecf07dfba1f6f2a.png",
  slides: [{
      id: 1,
      title: '这是什么玩意儿',
      image: 'https://ae01.alicdn.com/kf/U1c4d52eb66a04d939fcb6f0f6fe5988eH.jpg'
    },
    {
      id: 2,
      title: '无忧无虑的生活',
      image: 'https://ae01.alicdn.com/kf/U9757f3c745b642fa963e716397e13301N.jpg'
    },
    {
      id: 3,
      title: '一个神秘的物种',
      image: 'https://ae01.alicdn.com/kf/U90c866e0d61b45238e263cc7ec0f6fc8X.jpg'
    },
  ],
  menu: [{
      icon: 'https://xianhk.net/images/yq.png',
      label: '三星',
      id: 1,
    },
    {
      icon: 'https://xianhk.net/images/yq.png',
      label: '苹果',
      id: 2,
    },
    {
      icon: 'https://xianhk.net/images/yq.png',
      label: '小米',
      id: 3,
    },
    {
      icon: 'https://xianhk.net/images/yq.png',
      label: '华为',
      id: 4,
    },
    {
      icon: 'https://xianhk.net/images/yq.png',
      label: 'OPOP',
      id: 5,
    },
    {
      icon: 'https://xianhk.net/images/yq.png',
      label: 'VIVO',
      id: 6,
    },
    {
      icon: 'https://xianhk.net/images/yq.png',
      label: 'SONY',
      id: 7,
    },
  ],
  videos: [{
      id: 1,
      title: '新冠病毒大爆发',
      video: 'https://media.w3.org/2010/05/sintel/trailer.mp4',
      image: 'https://ae01.alicdn.com/kf/U1c4d52eb66a04d939fcb6f0f6fe5988eH.jpg'
    },
    {
      id: 2,
      title: '一场没有消炎的战争',
      video: 'http://vjs.zencdn.net/v/oceans.mp4',
      image: 'https://ae01.alicdn.com/kf/U9757f3c745b642fa963e716397e13301N.jpg'
    },
    {
      id: 3,
      title: '一个神秘的物种',
      video: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4',
      image: 'https://ae01.alicdn.com/kf/U90c866e0d61b45238e263cc7ec0f6fc8X.jpg'
    },
  ],
  videoList: [{
      "id": 1,
      "title": "校招产品求职之道30讲",
      "desc": "产品经理求职之道，总共31节内容，每周六更新1-2篇内容",
      "views": 3921,
      "period": 9,
      "live": true,
      "image": "https://images.unsplash.com/photo-1582599782475-aaec4fbd1b02?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 2,
      "title": "Python金融数据分析与量化投资",
      "desc": "如何用Python学习大数据的分析，让你轻轻松松玩转金融",
      "views": 3432,
      "period": 12,
      "live": false,
      "image": "https://images.unsplash.com/photo-1517408191923-f82a669f4ea1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 3,
      "title": "苏教版高三数学一轮复习",
      "desc": "在当下肺炎疫情时期如何让高三孩子们不耽误高考",
      "views": 543,
      "period": 8,
      "live": false,
      "image": "https://images.unsplash.com/photo-1543962218-19967d7eecea?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 4,
      "title": "Word应用案例从入门到精通",
      "desc": "从零基础开始彻底掌握Word，一周内学会",
      "views": 1325,
      "period": 15,
      "live": false,
      "image": "https://images.unsplash.com/photo-1529688519673-71bf74f168d0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 5,
      "title": "微课设计与制作II——技术篇",
      "desc": "针对现阶段最热门的技术设计短小精干的课程",
      "views": 432,
      "period": 6,
      "live": false,
      "image": "https://images.unsplash.com/photo-1579452458333-4787226ba9ca?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 6,
      "title": "网络课程与学习评价",
      "desc": "如何更好的学习网络课程",
      "views": 2819,
      "period": 10,
      "live": true,
      "image": "https://images.unsplash.com/photo-1580343477203-4bf294d0165a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
  ],
  answers: [{
      id: 1,
      NickName: '王大强',
      title: '职业经理人',
      audio: '',
      avatar: 'https://ae01.alicdn.com/kf/U7cb6979be3aa4251b0ecf07dfba1f6f2a.png',
      state: 1,
      praises: 483,
      question: '疫情已经持续了两个多月，对国家的经济影响到底有多大？',
      content: '水哥，微博这么夸你，有啥感受，为什么在微博中不回应一下？冉冉对你第一次见你的装束不那么清楚，你是否还记得',
      reply: [{
          NickName: '王花花',
          time: '前天 12:33',
          content: '中国加油，武汉加油，响应党的号召，跟党走...'
        },
        {
          NickName: '李牛牛',
          time: '前天 13:55',
          content: '湖北加油，中国加油，武汉加油，全面贯彻党的思想，响应党的号召，跟党走...'
        }
      ]
    },
    {
      id: 2,
      NickName: '张大纲',
      title: '农民',
      audio: '',
      praises: 332,
      avatar: 'https://ae01.alicdn.com/kf/U58a1747b4c594fdc829ed20ebf34313cw.png',
      state: 0,
      question: '疫情什么时候才能结束？',
      content: '夫妻皮肤都比较黑，吃什么可以改善生个好皮肤都宝宝'
    },
    {
      id: 3,
      NickName: '王鹏鹏',
      title: '程序猿',
      audio: '',
      praises: 1543,
      avatar: 'https://ae01.alicdn.com/kf/U0a85181bee8145bb85feb785b9ff731bd.png',
      state: 1,
      question: '疫情已经持续了两个多月，现在到底有没有到拐点？',
      content: '这次的疫情到底什么时候才能结束啊，我们快顶不住了救命啊！',
      reply: [{
          NickName: '王花花',
          time: '前天 12:33',
          content: '中国加油，武汉加油，响应党的号召，跟党走...'
        },
        {
          NickName: '李牛牛',
          time: '前天 13:55',
          content: '湖北加油，中国加油，武汉加油，全面贯彻党的思想，响应党的号召，跟党走...'
        }
      ]
    },
    {
      id: 4,
      NickName: '张妞妞',
      title: '产品狗',
      audio: '',
      praises: 643,
      avatar: 'https://ae01.alicdn.com/kf/U7cb6979be3aa4251b0ecf07dfba1f6f2a.png',
      state: 2,
      question: '疫情已经持续了两个多月，对国家的经济影响到底有多大？',
      content: '水哥，微博这么夸你，有啥感受，为什么在微博中不回应一下？冉冉对你第一次见你的装束不那么清楚，你是否还记得'
    },
    {
      id: 5,
      NickName: '刘二娃',
      title: '设计狮',
      audio: '',
      praises: 748,
      avatar: 'https://ae01.alicdn.com/kf/U205d8739152642f0a1c8dc229d09b364j.png',
      state: 0,
      question: '快坚持不住了，中国的创投圈现在怎么样？',
      content: '小猫姐，你觉得在结婚前有必要和男友做财产公正吗？女性这么在婚姻中保持经济独立'
    },
  ],
  examines: [{
      "id": 1,
      "title": "关于疫情对家庭的影响的调查",
      "desc": "产品经理求职之道，总共31节内容，每周六更新1-2篇内容",
      "time": '03-12 14:00',
      "doing": true,
      "new": true,
      "image": "https://images.unsplash.com/photo-1543962218-19967d7eecea?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 2,
      "title": "调查居民是否都有口罩等防护物资",
      "desc": "疫情期间勤洗手出门戴口罩，保护自己，人人有责",
      "time": '03-12 14:00',
      "doing": true,
      "new": true,
      "image": "https://images.unsplash.com/photo-1517408191923-f82a669f4ea1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 3,
      "title": "大家是否在疫情期间有囤货的举动",
      "desc": "物资紧缺，全民囤货",
      "time": '03-12 14:00',
      "doing": true,
      "image": "https://images.unsplash.com/photo-1582599782475-aaec4fbd1b02?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 4,
      "title": "企业复工后各单位是否做好了消毒等工作",
      "desc": "全国各地陆续开工，但务必做好防护工作",
      "time": '03-12 14:00',
      "image": "https://images.unsplash.com/photo-1529688519673-71bf74f168d0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 5,
      "title": "关于疫情对家庭的影响的调查",
      "desc": "产品经理求职之道，总共31节内容，每周六更新1-2篇内容",
      "time": '03-12 14:00',
      "image": "https://images.unsplash.com/photo-1579452458333-4787226ba9ca?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 6,
      "title": "调查居民是否都有口罩等防护物资",
      "desc": "疫情期间勤洗手出门戴口罩，保护自己，人人有责",
      "time": '03-12 14:00',
      "doing": true,
      "new": true,
      "image": "https://images.unsplash.com/photo-1517408191923-f82a669f4ea1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 7,
      "title": "大家是否在疫情期间有囤货的举动",
      "desc": "物资紧缺，全民囤货",
      "time": '03-12 14:00',
      "doing": true,
      "image": "https://images.unsplash.com/photo-1582599782475-aaec4fbd1b02?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 8,
      "title": "企业复工后各单位是否做好了消毒等工作",
      "desc": "全国各地陆续开工，但务必做好防护工作",
      "time": '03-12 14:00',
      "image": "https://images.unsplash.com/photo-1529688519673-71bf74f168d0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 9,
      "title": "关于疫情对家庭的影响的调查",
      "desc": "产品经理求职之道，总共31节内容，每周六更新1-2篇内容",
      "time": '03-12 14:00',
      "image": "https://images.unsplash.com/photo-1579452458333-4787226ba9ca?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
  ],
  votes: [{
      "id": 1,
      "title": "关于疫情对家庭的影响的投票",
      "desc": "产品经理求职之道，总共31节内容，每周六更新1-2篇内容",
      "time": '03-12 14:00',
      "doing": true,
      "new": true,
      "image": "https://images.unsplash.com/photo-1582599782475-aaec4fbd1b02?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 2,
      "title": "投票居民是否都有口罩等防护物资",
      "desc": "疫情期间勤洗手出门戴口罩，保护自己，人人有责",
      "time": '03-12 14:00',
      "doing": true,
      "new": true,
      "image": "https://images.unsplash.com/photo-1517408191923-f82a669f4ea1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 3,
      "title": "大家是否在疫情期间有囤货的举动",
      "desc": "物资紧缺，全民囤货",
      "time": '03-12 14:00',
      "doing": true,
      "image": "https://images.unsplash.com/photo-1543962218-19967d7eecea?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 4,
      "title": "企业复工后各单位是否做好了消毒等工作",
      "desc": "全国各地陆续开工，但务必做好防护工作",
      "time": '03-12 14:00',
      "image": "https://images.unsplash.com/photo-1529688519673-71bf74f168d0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
    {
      "id": 5,
      "title": "关于疫情对家庭的影响的投票",
      "desc": "产品经理求职之道，总共31节内容，每周六更新1-2篇内容",
      "time": '03-12 14:00',
      "image": "https://images.unsplash.com/photo-1579452458333-4787226ba9ca?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
    },
  ],
  postHtml: '<div class="html-content"><h3 class="text-base font-medium text-gray-800 py-3">简介</h3><p>2021年第一季度，小鹏汽车的车辆将会以高速自主导航驾驶（NGP）的方式，从广州出发一路北上并抵达北京，全程超过2000公里。 </p><div class="relative  overflow-hidden my-3" style="width: auto; height: auto;"><div class="bg-cover bg-no-repeat bg-center w-full h-full pt-2/3 rounded" style="background-image: url(&quot;https://img.36krcdn.com/20201025/v2_e1164247ceae40268475e9affc21b632_img_jpeg&quot;);"></div></div><p> 在母校华南理工大学，小鹏汽车董事长何小鹏立下了一个新的Flag：2021年第一季度，小鹏汽车的车辆将会以高速自主导航驾驶（NGP）的方式，从广州出发一路北上并抵达北京，全程超过2000公里。 </p><h3 class="text-base font-medium my-3 text-gray-800">小P迎更新：打败车内手机支架</h3><p> 如何定义一个好的智能座舱？小鹏汽车副总裁纪宇认为，好用的驾乘智能、便捷的车载交互、广泛的智能生态接入是其中必备的三个要素。 </p></div>'
}