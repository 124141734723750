<template>
  <div id="app">
    <header-pc></header-pc>
    <router-view/>
  </div>
</template>
<script>
import HeaderPc from './components/header-pc'
export default {
  components: {
    HeaderPc
  }
}
</script>
<style lang="stylus">

</style>
