<template>
  <div class="bg-white flex">
    <media avatar-size="32" :src="avatar" class="mr-3"></media>
    <div class="flex-1">
      <div class="flex">
        <div class="flex-1">
          <div class="text-sm text-gray-600 font-medium">{{nickName}}</div>
          <div class="mt-1-1/2 text-xs text-gray-light">{{time}}</div>
        </div>
        <icon-text :icon="praised ? 'icon-good-fill': 'icon-good'" :icon-color="praised ? 'orange-400':''" :text="praiseNum" @click="$emit('on-praise')"></icon-text>
      </div>
      <div class="py-3 text-gray-800 text-sm leading-6" v-if="content">{{content}}</div>
      <slot></slot>
      <divider class="mb-3" v-if="!noDivider"></divider>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    avatar: String,
    time: String,
    content: String,
    nickName: String,
    praiseNum: {
      type: [Number, String],
      default: ''
    },
    praised: {
      type: Boolean,
      default: false
    },
    noDivider: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style>

</style>