<template>
  <header class="header hidden sm:block  bg-white text-gray-700 shadow-lg relative z-50">
    <div class="container mx-auto px-4 py-4">
      <div class="flex items-center">
        <div class="flex-1 flex items-center">
          <img src="../assets/logo.png" alt="" class="logo h-8 mr-2">
          <!-- <span class="text-xl font-medium">商城</span> -->
        </div>
        <div class="flex items-center">
          <router-link to="/search" class="input-wrap relative flex flex-1  items-center ml-3 w-48">
            <span
              class="z-10 leading-4 font-normal text-center text-gray-500 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-2"
            >
              <i class="iconfont icon-search text-2xl"></i>
            </span>
            <div
              class="px-4 py-2  text-gray-500 relative bg-gray-200 rounded text-sm outline-none focus:bg-white focus:outline-none focus:shadow-outline w-full pl-10"
            >
              搜索
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- <divider></divider> -->
  </header>
</template>

<script>
export default {

}
</script>

<style>

</style>