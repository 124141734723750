<template>
  <div class="flex items-center" :class="vertical ? 'flex-col':''" @click="$emit('click')">
    <i class="iconfont" :class="[icon, vertical?'':'mr-1',`text-${ iconColor ? iconColor : color}`, `text-${iconSize}`]" v-if="icon"></i>
    <span class="text-xs " :class="`text-${color}`">{{text}}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    text: String,
    vertical: {
      type: Boolean,
      default: false
    },
    iconSize: {
      type: String,
      default: 'xl'
    },
    color: {
      type: String,
      default: 'gray-light'
    },
    iconColor: String
  }
}
</script>

<style>

</style>