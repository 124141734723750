<template>
  <button 
    class="btn relative hover:bg-opacity-90 " 
    @click="$emit('click')" 
    :class="[
      `border border-solid border-${color}`,
      outline ? `text-${color}` : `text-${textColor}`,
      outline ? 'bg-transparent' : `bg-${color}`,
      radius ? `rounded-${radius}`: 'rounded',
      btnSize,
      padding ,
      block && 'block w-full'
    ]"
  >
    <div class="flex items-center justify-center leading-4 h-full whitespace-no-wrap" :class="iconRight ? 'flex-row-reverse':''">
      <i class="iconfont" :class="[icon, iconRight ? 'ml-1':'mr-1', `text-${iconSize}`]"></i>
      <span :class="[`text-${textSize}`]">
        <slot></slot>
      </span>
    </div>
    <router-link :to="url" class="absolute inset-0" v-if="url"></router-link>
  </button>
</template>

<script>
export default {
  computed: {
    btnSize() {
      if (this.size) {
        
        switch (this.size) {
          case 'xs':
            return 'px-1-1/2 h-6'
            break;
          case 'sm':
            return 'px-2 h-8'
            break;
          case 'md':
            return 'px-3 h-10'
            break;
          case 'lg':
            return 'px-5 h-11'
            break;
          case 'xl':
            return 'px-6 h-12'
            break;
        
          default:
            return 'px-3 h-10'
            break;
        }
      }else{
        return 'px-4 py-3'
      }
    }
  },
  props: {
    color: {
      type:String,
      default: 'primary'
    },
    url: {
      type:String,
      default: ''
    },
    icon: String,
    size: {
      type: String,
      default: ''
    },
    padding: String,
    textColor: {
      type: String,
      default: 'white'
    },
    textSize: {
      type: String,
      default: 'sm'
    },
    iconSize: {
      type: String,
      default: 'xl'
    },
    radius: {
      type: String,
      default: ''
    },
    iconRight: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="stylus">
.btn
  outline: none !important
</style>