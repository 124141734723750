import Vue from 'vue'
import './cube-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import './components/components'
import './styles/main.styl'

Vue.config.productionTip = false

router.beforeEach(function (to, from, next) {
  // console.log(to.matched);
  if (!store.state.loggedIn && to.matched.some(item => {
      // 如果路由中的mata.login_required为true,则页面必须登录才可访问
      return item.meta.login_required;
    }))
    next('/login');
  else
    next();
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')