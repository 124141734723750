<template>
  <div class="header-bar z-40 "
    :class="transparent ? 'fixed inset-x-0 top-0' : 'relative'"
  >
    <div 
      class="flex items-center justify-between h-12 transition-3" 
      :class="[
        transparent ? '': `bg-${bgColor}`,
      ]"
    >
      <div class="back-icon px-3" @click="$router.go(-1)" v-if="!noBack">
        <i class="iconfont icon-arrow-left text-3xl transition-3" :class="transparent ? 'text-white':`text-${textColor}`"></i>
      </div>
      <slot></slot>
      <div class="title cc px-16 truncate text-lg font-medium transition-3" :class="transparent ? 'text-white':`text-${textColor}`" v-if="title">{{title}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBar",
  props: {
    title: String,
    noBack: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: 'gray-50'
    },
    textColor: {
      type: String,
      default: 'gray-800'
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
