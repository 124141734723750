<template>
  <div 
    class="flex items-center justify-center"
    :style="{width: `${wrapSize || size}px`, height: `${wrapSize || size}px`}" 
    :class="[block ? 'block':'inline-block', bgColor && `bg-${bgColor}`]"
    @click="$emit('click')"
  >
    <i 
      class="iconfont" 
      :class="[icon,`text-${color}`]" :style="{fontSize: size + 'px'}"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    block: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '20'
    },
    color: {
      type: String,
      default: 'primary'
    },
    bgColor: String,
    wrapSize: String,
  },
  computed: {
    
  },
  data(){
    return{
      
    }
  },
  methods:{
    
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>