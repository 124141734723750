import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import mutations from './mutations'
import actions from './actions'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    userInfo: {
      type: 1
    },
  },
  getters,
  mutations: mutations,
  actions: actions,
  modules: {}
})