<template>
  <div class="relative  overflow-hidden" :class="{'rounded-full': avatarSize}" :style="{width:size(avatarSize || width),height:size(avatarSize || height)}" @click="$emit('click')">
    <div class="bg-cover bg-no-repeat bg-center w-full h-full" :class="[ratio && !avatarSize && `pt-${ratio}`, `rounded${radius=='base'? '': `-${radius}`}`]" :style="{backgroundImage: `url(${src})`}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    avatarSize: String,
    width: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: 'auto'
    },
    radius: {
      type: String,
      default: 'base'
    },
    ratio: {
      type: String,
      default: '2/3'
    }
  },
  computed: {
    
  },
  methods: {
    size(size) {
      return size.indexOf('%') != -1 || size == 'auto' ? size : size + 'px'
    }
  }
}
</script>

<style>

</style>