<template>
  <div class="flex flex-col items-center relative rounded overflow-hidden p-1" @click="$emit('click')">
    <img :src="imgIcon" alt="" class="object-contain h-10 " v-if="imgIcon">
    <div class="w-10 h-10 rounded-full flex items-center justify-center" :class="`bg-${color}-100`" v-else>
      <i class="iconfont text-3xl" :class="`${icon} text-${color}-500`"></i>
    </div>
    <div class="text-xs mt-2 text-gray-700 md:text-sm">{{label}}</div>
    <router-link v-if="url" :to="url" class="absolute inset-0 hover:bg-gray-500 hover:bg-opacity-25"></router-link>
  </div>
</template>

<script>
export default {
  props: ['icon','imgIcon','label','color','url'],
}
</script>

<style>

</style>