import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/tabs/home'
  },
  {
    path: '/tabs',
    component: () => import('../pages/tabs.vue'),
    children: [{
        path: 'home',
        component: () => import('../pages/home/'),
      },
      {
        path: 'category',
        component: () => import('../pages/category/'),
      },
      {
        path: 'cart',
        meta: {
          login_required: true,
        },
        component: () => import('../pages/cart/'),
      },
      {
        path: 'person',
        meta: {
          login_required: true,
        },
        component: () => import('../pages/person/'),
      },
    ]
  },


  {
    path: '/login/',
    component: () => import('../pages/login.vue')
  },
  {
    path: '/register/:typeId',
    component: () => import('../pages/register.vue')
  },
  {
    path: '/goods-detail/:goodsId',
    component: () => import('../pages/details/goods-detail.vue')
  },
  {
    path: '/order-detail/:orderId',
    component: () => import('../pages/details/order-detail.vue')
  },
  {
    path: '/return-sale/:orderId',
    component: () => import('../pages/person/return-sale.vue')
  },
  {
    path: '/apply-after/:orderId',
    component: () => import('../pages/person/apply-after.vue')
  },
  {
    path: '/order/',
    component: () => import('../pages/cart/order.vue')
  },
  {
    path: '/buy-goods/',
    component: () => import('../pages/cart/buy-goods.vue')
  },
  {
    path: '/paid/',
    component: () => import('../pages/cart/paid.vue')
  },
  {
    path: '/promote-code/',
    component: () => import('../pages/person/promote-code.vue')
  },
  {
    path: '/my-order/',
    component: () => import('../pages/person/my-order.vue')
  },
  {
    path: '/my-score/',
    component: () => import('../pages/person/my-score.vue')
  },
  {
    path: '/message/',
    component: () => import('../pages/person/message.vue')
  },
  {
    path: '/my-address/',
    component: () => import('../pages/person/my-address.vue')
  },
  {
    path: '/platform-info/',
    component: () => import('../pages/person/platform-info.vue')
  },
  {
    path: '/online-service/',
    component: () => import('../pages/person/online-service.vue')
  },
  {
    path: '/my-bankcard/',
    component: () => import('../pages/person/my-bankcard.vue')
  },
  {
    path: '/my-commission/',
    component: () => import('../pages/person/my-commission.vue')
  },
  {
    path: '/commission-withdraw/',
    component: () => import('../pages/person/commission-withdraw.vue')
  },
  {
    path: '/my-extension/',
    component: () => import('../pages/person/my-extension.vue')
  },
  {
    path: '/my-profile/',
    component: () => import('../pages/person/my-profile.vue')
  },


  {
    path: '/search',
    component: () => import('../pages/search.vue')
  },

]

const router = new VueRouter({
  routes
})

export default router