<template>
  <div 
    class="px-1-1/2 rounded-full  text-white text-xs scale-85 leading-4"
    :class="[`bg-${color}-500`]"
  >
    <slot></slot>
  </div> 
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'red'
    }
  },
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>

</style>