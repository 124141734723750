<template>
  <div class="page flex flex-col box-border relative  md:bg-white" :class="[`h-${height}`,`bg-${bgColor}`]">
    <div class="container mx-auto md:px-4 md:hidden">
      <header-bar :title="title" :noBack="noBack" v-if="!noHeader" :transparent ="headerTransparent">
        <slot name="header-after"></slot>
      </header-bar>
    </div>
    
    <slot name="fixed-top"></slot>
    <div class="flex-1 overflow-hidden md:mt-4">
      <cube-scroll
        class="md:hidden"
        ref="scroll"
        :data="list"
        :options="options"
        :scrollEvents="['scroll']"
        @scroll="onScroll"
        >
        <div class="container mx-auto">
          <slot></slot>
        </div>
      </cube-scroll>
      <div class="h-full overflow-y-auto overflow-x-hidden hidden md:block">
        <div class="container mx-auto">
          <slot></slot>
        </div>
      </div>
    </div>
    <slot name="root"></slot>
    <slot name="footer"></slot>
    <div class="masker fixed inset-0 bg-black bg-opacity-50 z-40" v-if="showDialog" @click="$emit('mask-click')"></div>
    <div class="dialog z-50 fixed inset-x-0 bottom-0 max-h-screen" :class="{'show':showDialog}">
      <slot name="dialog"></slot>
    </div>  
  </div>
</template>

<script>
import HeaderBar from './header-bar'
export default {
  props: {
    title: String,
    list: Array,
    bgColor: {
      type: String,
      default: 'gray-150'
    },
    noBack: {
      type: Boolean,
      default: false
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    headerTransparent: {
      type: Boolean,
      default: false
    },
    scrollbar: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'screen'
    }
  },
  components: {
    HeaderBar
  },
  computed: {
    options() {
      return {
        scrollbar: this.scrollbar,
        startY: 0
      }
    },
    scrollbarObj: function() {
      return this.scrollbar ? { fade: this.scrollbarFade } : false
    }
  },
  watch: {
    scrollbarObj: {
      handler() {
        this.rebuildScroll()
      },
      deep: true
    },
    startY() {
      this.rebuildScroll()
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    onScroll(e) {
      this.$emit('on-scroll',e)
    },
  }
}
</script>

<style lang="stylus">
.dialog
  transition: all .3s
  background-color #fff
  border-radius: 10px 10px 0 0
  transform: translateY(100%)
  &.show
    transform: translateY(0)
</style>