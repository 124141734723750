<template>
  <div class="bg-white mb-3" >
    <router-link :to="url" class="block">
      <div class="line2 px-4 py-3">
        <tag :color="tagColor" v-if="tag">{{tag}}</tag>
        <span class="font-medium leading-6">{{title}}</span>
      </div>
      <div class="leading-6 text-sm text-gray-700 px-4 pb-3" v-if="content">{{content}}</div>
      <slot></slot>
    </router-link>
    <slot name="footer"></slot>
  </div>
</template>

<script>
// import { UserInfo } from "./";

export default {
  // components: {
  //   UserInfo
  // },

  props: {
    tag: String,
    tagColor: String,
    title: String,
    content: String,
    avatar: String,
    nickName: String,
    userNote: String,
    url: {
      type: String,
      default: ''
    }
  },
  created() {
    
  }
}
</script>

<style>

</style>