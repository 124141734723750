<template>
  <div class="tabs-title pl-4 pr-3 flex items-center" :class="[title ? 'py-3':'py-1', {'bg-white': !noBg}]">
    <div class="text-lg font-medium flex-1 overflow-hidden" v-if="title">{{title}}</div>
    <div class="tabs-wrap flex-1 overflow-hidden" v-else>
      <cube-scroll-nav-bar :current="current" :labels="tabList" @change="tabChangeHandler" />
    </div>
    <router-link class="flex items-center ml-3" :to="moreLink" v-if="moreLink">
      <span class="text-xs text-gray-500">更多</span>
      <i class="iconfont icon-arrow-right text-gray-400 text-lg"></i>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    tabList: {
      type: Array,
      default: () => []
    },
    // current: {
    //   type: [String, Number],
    //   default: 0
    // },
    moreLink: {
      type: String,
      default: ''
    },
    noBg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      current: ''
    }
  },
  methods: {
    tabChangeHandler(cur) {
      this.current = cur
      this.$emit('tab-change',cur,this.tabList.indexOf(cur))
    }
  },
  created(){
    this.current = this.tabList[0]
  }
}
</script>

<style>

</style>