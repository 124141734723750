<template>
  <div class="list">
    <slot v-if="!empty"></slot>
    <div class="px-4 py-8 flex flex-col justify-center items-center text-gray-500" v-if="empty">
      <i class="iconfont icon-empty-box text-gray-400 text-6xl mb-4"></i>
      <div class="text-sm">{{emptyText}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    empty: {
      type: Boolean,
      default: false
    },
    emptyText: {
      type: String,
      default: '暂无数据'
    },
  }
}
</script>

<style>

</style>