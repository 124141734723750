<template>
  <div class="relative  bg-white" :class="['pl-4',{'flex-row-reverse': imgRight}]" @click="$emit('click')">
    <div class="pr-4 py-3 relative hairline-bottom">
      <div class=" flex items-start relative">
        <media :src="image" :ratio="imgRatio" :avatar-size="avatarSize" :class="imgRight ? 'ml-4':'mr-4'" :width="imgWidth" v-if="image">
          <i class="iconfont icon-play-fill text-5xl text-white cc" v-if="isVideo"></i>
        </media>
        <div class="flex-1 flex flex-col">
          <div class=" leading-5 flex-1">
            <div class="text-gray-dark text-sm line2">{{title}}</div>
            <div class="mt-1 text-gray-light text-xs" v-if="note">{{note}}</div>
            <slot></slot>
          </div>
          <slot name="footer"></slot>
        </div>
        <router-link v-if="url" :to="url" class="absolute inset-0 hover:bg-gray-500 hover:bg-opacity-25"></router-link>
      </div>
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
import {Media} from './'
export default {
  components: {
    Media
  },
  props: {
    imgWidth: {
      type: String,
      default: '120'
    },
    avatarSize: {
      type: String,
      default: ''
    },
    url: [String, Object],
    image: String,
    imgRatio: {
      type: String,
      default: '3/4'
    },
    title: String,
    note: String,
    isVideo: {
      type: Boolean,
      default: false
    },
    imgRight: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style>

</style>