<template>
  <div class="bg-white h-12 flex items-center justify-between px-4 " >
    <div class="flex items-start">
      <div class="flex flex-col items-center relative">
        <i class="iconfont icon-add-cart text-3xl"></i>
        <div class="text-xs transform scale-85">购物车</div>
      </div>
      <badge class="-ml-1">{{count}}</badge>
    </div>
    <div class="flex items-center">
      <btn radius="full" color="orange-500" size="md" @click="$emit('on-cart')">加入购物车</btn>
      <btn radius="full" color="red-500" size="md" class="ml-3" @click="$emit('on-buy')">立即购买</btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: String,
      default: '0'
    }
  },
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>

</style>