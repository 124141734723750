<template>
  <card 
    class="card overflow-hidden relative rounded-sm"
    color="white"
    :image="image"
    :imgRatio="imgRatio"
    :url="url"
  >
    <div class="p-2 md:px-0">
      <div class="truncate">
        <tag :color="tagColor" padding="p-1">{{tag}}</tag>
        <span class="text-sm">{{title}}</span>
      </div>
      <div class="text-xs text-gray-light mt-1">{{note}}</div>
      <div class="flex justify-between mt-1">
        <div class="font-medium text-red-500" v-if="price"><span class="text-xs">¥</span>{{price}}</div>
        <div class="text-xs text-gray-light" v-if="count">{{count}}</div>
      </div>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    title: String,
    note: String,
    tag: String,
    count: String,
    price: String,
    tagColor: {
      type: String,
      default: 'red'
    },
    imgRatio: {
      type: String,
      default: '6/5'
    },
    image: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'white'
    },
    url: {
      type: [String, Object],
      default: ''
    }
  }
}
</script>

<style>

</style>