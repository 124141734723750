<template>
  <div class="input-wrap relative flex flex-1  items-center ml-3">
    <span
      class="z-10 leading-4 font-normal text-center text-gray-500 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-2"
    >
      <i class="iconfont icon-search text-2xl"></i>
    </span>
    <input
      type="text"
      :placeholder="placeholder"
      :value="value"
      @input="update($event.target.value)"
      class="px-2 py-2  placeholder-gray-500 text-gray-700 relative bg-gray-200 rounded text-sm outline-none focus:bg-white focus:outline-none focus:shadow-outline w-full pl-10"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    placeholder: String,
    value: [String, Number]
  },

  methods: {
    update(value) {
      console.log(value);
      this.$emit("input", value);
    }
  }
};
</script>

<style></style>
