var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn relative hover:bg-opacity-90 ",class:[
    ("border border-solid border-" + _vm.color),
    _vm.outline ? ("text-" + _vm.color) : ("text-" + _vm.textColor),
    _vm.outline ? 'bg-transparent' : ("bg-" + _vm.color),
    _vm.radius ? ("rounded-" + _vm.radius): 'rounded',
    _vm.btnSize,
    _vm.padding ,
    _vm.block && 'block w-full'
  ],on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"flex items-center justify-center leading-4 h-full whitespace-no-wrap",class:_vm.iconRight ? 'flex-row-reverse':''},[_c('i',{staticClass:"iconfont",class:[_vm.icon, _vm.iconRight ? 'ml-1':'mr-1', ("text-" + _vm.iconSize)]}),_c('span',{class:[("text-" + _vm.textSize)]},[_vm._t("default")],2)]),(_vm.url)?_c('router-link',{staticClass:"absolute inset-0",attrs:{"to":_vm.url}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }