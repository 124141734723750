<template>
  <div class="px-4 py-3 flex items-center">
    <div class="flex items-center">
      <img src="../assets/logo.png" alt="" class="h-6" />
      
    </div>
    <router-link class="flex-1" :to="link">
      <div class="input-wrap relative flex flex-1  items-center ml-3">
        <span
          class="z-10 leading-4 font-normal text-center text-gray-500 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-2"
        >
          <i class="iconfont icon-search text-2xl"></i>
        </span>
        <div
          class="px-2 py-2  text-gray-500 relative bg-gray-200 rounded text-sm outline-none focus:bg-white focus:outline-none focus:shadow-outline w-full pl-10"
        >
          {{ placeholder }}
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchInput: ""
    };
  },
  props: {
    logo: String,
    title: String,
    placeholder: String,
    link: {
      type: String,
      default: "/"
    }
  }
};
</script>

<style></style>
