<template>
  <div class="card overflow-hidden relative" :class="[color && `bg-${color}` ]"
  >
    <media :src="image" radius="none" :ratio="imgRatio" v-if="image">
      <slot name="image"></slot>
    </media>
    <div class="px-4 md:px-0 py-3 flex items-center" v-if="title">
      <div class="flex-1 overflow-hidden">
        <div>
          <tag color="red" class="mr-2" v-if="tag">{{tag}}</tag>
          <span class="font-medium truncate text-gray-dark">{{title}}</span>
        </div>
        <div class="mt-2 text-xs text-gray-light">{{note}}</div>
      </div>
      <slot name="info-after"></slot>
    </div>
    <slot></slot>
    <router-link :to="url" v-if="url" class="absolute inset-0 hover:bg-black hover:bg-opacity-10 z-10"></router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    note: String,
    tag: String,
    tagColor: {
      type: String,
      default: 'red'
    },
    imgRatio: {
      type: String,
      default: '9/16'
    },
    image: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'white'
    },
    url: {
      type: [String, Object],
      default: ''
    }
  }
}
</script>

<style>

</style>