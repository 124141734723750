<template>
  <arrow-wrap>
    <div class="flex items-center">
      <i class="iconfont icon-map mr-3 text-3xl text-gray-500"></i>
      <div class="flex-1 overflow-hidden">
        <div class="">
          <span class="text-lg font-medium text-gray-dark">{{name}}</span>
          <span class="text-sm ml-2 text-gray-light">{{tel}}</span>
        </div>
        <div class="text-sm text-gray-dark mt-2 leading-5">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="address-line"></div>
  </arrow-wrap>
</template>

<script>
export default {
  props: {
    name: String,
    tel: String,
  },
  data(){
    return{
      
    }
  },
  methods:{},
  created(){}
}
</script>

<style lang='stylus'>

.address-line
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABGUAAAAGCAMAAABdG9OLAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAPUExURf///xWn1tojP+2Rn4rT6zXCHwQAAABPSURBVFjD7dDBCQAhAAPBeNp/zZZwIgR8TAoIw2b8bOVo87vZPDtf42bk5ORPyCM+OTl5VR7xycnJq/KIT05OXpVHfHJy8qo84pOTk1flG/eHGIuy2bRIAAAAAElFTkSuQmCC');
  height 2px;
  background-repeat: repeat-x;
  background-position: 0 0;
  background-size: 100% 2px;
  position absolute
  left 0
  right 0
  bottom 0
</style>